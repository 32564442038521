import upperFirst from 'lodash/upperFirst'
import pick from 'lodash/pick'

import {
  CarCarPremiumFeaturesNames,
  CarPremiumFeatures,
  CarProfile,
  CarProfileFull,
} from '@/models/carProfile'

import { commonCase } from './strings'
import { isUndefined } from 'lodash'

export const normalizeCarName = (car: CarProfile) => {
  const makeId = car.model_make_id ? upperFirst(car.model_make_id) : ''
  const modelName = car.model_name ? ` ${car.model_name}` : ''
  const modelYear = car.model_year ? ` ${car.model_year}` : ''

  return `${makeId}${modelName}${modelYear}`
}

const CAR_POSTFIX_MAP: { [key in keyof CarProfileFull]?: string } = {
  daily_price: '[$]',
  weekly_price: '[$]',
  monthly_price: '[$]',
  daily_distance_limit: '[km]',
  extra_distance_charge: '[$/km]',
}
export const getPostfixByCarKey = (key: keyof CarProfileFull): string =>
  CAR_POSTFIX_MAP[key] || ''

export interface ExtractedFeature {
  key: keyof CarPremiumFeatures
  label: string
  enabled: boolean | null
  price: number | null
}

export const extractPremiumFeatures = (
  car: CarProfile,
  features?: CarCarPremiumFeaturesNames[]
): ExtractedFeature[] => {
  const toPick: CarCarPremiumFeaturesNames[] = features || [
    'chauffeur_service',
    'full_tank',
  ]

  const picked = pick(car, toPick)

  return Object.entries(picked).map<ExtractedFeature>(entity => {
    const key = entity[0] as keyof CarPremiumFeatures
    const value = entity[1]

    const priceKey = `${key}_price` as keyof CarPremiumFeatures
    const price = car[priceKey] as number | null
    const mandatoryKey = `${key}_mandatory` as keyof CarPremiumFeatures
    const mandatory = (car[mandatoryKey] ?? null) as boolean | null

    return {
      key,
      price,
      enabled: value,
      label: commonCase(key, true),
      ...(!isUndefined(car[mandatoryKey]) ? { mandatory } : {}),
    }
  })
}
